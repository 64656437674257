import React from "react";

const Modal = (props) => {
    return (
        <div className="fixed z-10 inset-0 overflow-auto flex items-center justify-center">
            <div className="bg-gray-500 bg-opacity-75 fixed inset-0"></div>
            <div className="relative bg-white rounded-lg shadow-xl transform transition-all w-full max-w-3xl max-h-screen overflow-auto p-6">
                {props.children}
            </div>
        </div>
    );
}

export default Modal;
