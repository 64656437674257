import {useEffect, useState} from "react";
import {findPaymentsWithPagination} from "../api/eventGroupApiClient";

export const usePayments = (customerId, fromDate, toDate, page, pageSize, reloadTrigger) => {
    const [payments, setPayments] = useState([]);
    const [pagination, setPagination] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();
        const paymentsWithPagination = findPaymentsWithPagination(page, pageSize, customerId, fromDate, toDate, abortController.signal);

        paymentsWithPagination
            .then(
                (data) => {
                    const payments = data.payments;
                    const pagination = data.pagination;

                    setPayments(payments)
                    setPagination(pagination)
                }
            )
            .catch(
                (error) => console.log(error)
            );

        return () => {
            abortController.abort();
        };
    }, [customerId, fromDate, toDate, page, pageSize, reloadTrigger]);

    return {payments : payments, pagination};
};