import {API_URL, handleErrorResponse} from "../utility/utility";

export async function findEventGroupsWithPagination(page, limit, filterStatus = null, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('limit', limit);

    if (filterStatus) {
        params.append('status', filterStatus);
    }

    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/event-groups?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch event groups');

    return response.json();
}

export async function findProductsWithPagination(page, limit, filterStatus = null, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    if (filterStatus) {
        params.append('status', filterStatus);
    }
    params.append('page', page);
    params.append('limit', limit);

    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/products?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch products');

    return response.json();
}

export async function findCustomerProductsWithPagination(page, limit, customerId = null, filterFirstName = null, filterLastName = null, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('limit', limit);

    if (customerId) {
        params.append('customerId', customerId);
    }

    if (filterFirstName) {
        params.append('firstName', filterFirstName);
    }

    if (filterLastName) {
        params.append('lastName', filterLastName);
    }

    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/customer-products?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch customer products');

    return response.json();
}

export async function createInvoice(orderId) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/invoices`, {
        method  : 'POST',
        body    : JSON.stringify({orderId : orderId}),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to create invoice');
}

export async function cancelOrder(orderId) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/orders/${orderId}/cancel`, {
        method  : 'POST',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to cancel order');
}

export async function findInvoicesWithPagination(page, limit, customerId = null, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('limit', limit);

    if (customerId) {
        params.append('customerId', customerId);
    }

    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/invoices?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch invoices');

    return response.json();
}

export async function findPaymentsWithPagination(page, limit, customerId = null, fromDate = null, toDate = null, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('limit', limit);

    if (customerId) {
        params.append('customerId', customerId);
    }

    if (fromDate) {
        params.append('fromDate', fromDate);
    }

    if (toDate) {
        params.append('toDate', toDate);
    }

    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/payments?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch payments');

    return response.json();
}

export async function findOrdersWithPagination(page, limit, customerId = null, paymentStatus = null, fromDate = null, toDate = null, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('limit', limit);

    if (customerId) {
        params.append('customerId', customerId);
    }

    if (paymentStatus) {
        params.append('paymentStatus', paymentStatus);
    }

    if (fromDate) {
        params.append('fromDate', fromDate);
    }

    if (toDate) {
        params.append('toDate', toDate);
    }

    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/orders?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch orders');

    return response.json();
}

export async function findPayableOrders(customerId, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/orders/payable?customerId=${customerId}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch payable orders');

    return response.json();
}

export async function getOrdersTotal(fromDate = null, toDate = null, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    if (fromDate) {
        params.append('fromDate', fromDate);
    }

    if (toDate) {
        params.append('toDate', toDate);
    }

    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/orders/total?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch total orders');

    return await response.json();
}

export async function getCustomerProduct(customerProductId) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/customer-products/${customerProductId}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to fetch customer product');

    return await response.json();
}

export async function updateCustomerProduct(customerProductId, credits = null, expiredAt = null) {
    if (!customerProductId) {
        throw new Error("The customerProductId is required.");
    }

    const token = localStorage.getItem('ACCESS_TOKEN');

    let data = {};
    if (credits) {
        data.credits = credits;
    }
    if (expiredAt) {
        data.expiredAt = expiredAt;
    }

    if (Object.keys(data).length === 0) {
        throw new Error("At least one field is required.");
    }

    const response = await fetch(`${API_URL}/api/customer-products/${customerProductId}`, {
        method  : 'PATCH',
        body    : JSON.stringify(data),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to update customer product');
}

export async function createCustomerProduct(
    customerId,
    productId,
    orderDate,
    paymentMethod,
    activationType = null
) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const data = {
        customerId    : customerId,
        productId     : productId,
        orderDate     : orderDate,
        paymentMethod : paymentMethod
    }

    if (activationType) {
        data.activationType = activationType;
    }

    const response = await fetch(`${API_URL}/api/customer-products`, {
        method  : 'POST',
        body    : JSON.stringify(data),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to create customer product');
}

export async function createPayment(customerId, orderId, paymentMethod, paymentDate) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/payments`, {
        method  : 'POST',
        body    : JSON.stringify({
            customerId    : customerId,
            orderId       : orderId,
            paymentMethod : paymentMethod,
            paymentDate   : paymentDate
        }),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to create payment');
}

export async function findBookingGroupsWithPagination(page, limit, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('limit', limit);

    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/booking-groups?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch booking groups');

    return response.json();
}

export async function findBookingGroupsAll(signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/booking-groups-all`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch booking groups');

    return response.json();
}

export async function getEventGroup(eventGroupId) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/event-groups/${eventGroupId}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to fetch event group');

    return await response.json();
}

//getBookingGroup
export async function getBookingGroup(bookingGroupId) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/booking-groups/${bookingGroupId}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to fetch booking group');

    return await response.json();
}

export async function getProduct(productId) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/products/${productId}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to fetch product');

    return await response.json();
}

export async function getInvoice(invoiceId) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/invoices/${invoiceId}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to fetch invoice');

    return await response.json();
}

export async function getInvoicePdf(invoiceId) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/invoices/${invoiceId}/pdf`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/pdf',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to fetch invoice pdf');

    return await response.blob();
}

export async function getEvent(eventId) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/events/${eventId}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to fetch event');

    return await response.json();
}

export async function updateEvent(
    eventId,
    name = null,
    seats = null,
    trainerIds = null,
    startedAt = null,
    endedAt = null
) {
    if (!eventId) {
        throw new Error("The eventId is required.");
    }

    const token = localStorage.getItem('ACCESS_TOKEN');

    let data = {};

    if (name) {
        data.name = name;
    }

    if (seats) {
        data.seats = seats;
    }

    if (trainerIds) {
        data.trainerIds = trainerIds;
    }

    if (startedAt) {
        data.startedAt = startedAt;
    }

    if (endedAt) {
        data.endedAt = endedAt;
    }

    if (Object.keys(data).length === 0) {
        throw new Error("At least one field is required.");
    }

    const response = await fetch(`${API_URL}/api/events/${eventId}`, {
        method  : 'PATCH',
        body    : JSON.stringify(data),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to update event');
}

export async function createEventGroup(eventType, locationId, name, seats, color, cancellationCreditRefundPeriod, description = null, image = null) {
    if (!eventType || !locationId || !name || !seats || !color || !cancellationCreditRefundPeriod) {
        throw new Error("All fields except 'description' and 'image' are required.");
    }

    const token = localStorage.getItem('ACCESS_TOKEN');

    // Create FormData for file upload
    const formData = new FormData();
    formData.append('eventType', eventType);
    formData.append('locationId', locationId);
    formData.append('name', name);
    formData.append('seats', seats);
    formData.append('color', color);
    formData.append('cancellationCreditRefundPeriod', cancellationCreditRefundPeriod);

    // Append optional fields
    if (description) {
        formData.append('description', description);
    }
    if (image) {
        formData.append('image', image);
    }

    const response = await fetch(`${API_URL}/api/event-groups`, {
        method  : 'POST',
        body    : formData,
        headers : {
            'Accept'        : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to create event group');
}

//createBookingGroup(name, eventGroupIds)
export async function createBookingGroup(name, eventGroupIds) {
    if (!name || !eventGroupIds) {
        throw new Error("All fields are required.");
    }

    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/booking-groups`, {
        method  : 'POST',
        body    : JSON.stringify({
            name          : name,
            eventGroupIds : eventGroupIds
        }),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to create booking group');
}

export async function createProduct(
    productType,
    name,
    description,
    bruttoPrice,
    isRestrictedOnePerUser,
    isPublic,
    order,
    isShopVisibleAfterStart = null,
    credit = null,
    validity = null,
    activationType = null,
    validityUnit = null,
    eventGroupId = null,
    eventIds = null,
    bookingGroupIds = null
) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const data = {
        productType            : productType,
        name                   : name,
        description            : description,
        bruttoPrice            : bruttoPrice,
        isRestrictedOnePerUser : isRestrictedOnePerUser,
        isPublic               : isPublic,
        order                  : order
    }

    if (isShopVisibleAfterStart !== null) {
        data.isShopVisibleAfterStart = isShopVisibleAfterStart;
    }

    if (credit !== null) {
        data.credit = credit;
    }

    if (validity !== null) {
        data.validity = validity;
    }

    if (activationType !== null) {
        data.activationType = activationType;
    }

    if (validityUnit !== null) {
        data.validityUnit = validityUnit;
    }

    if (eventGroupId !== null) {
        data.eventGroupId = eventGroupId;
    }

    if (eventIds !== null) {
        data.eventIds = eventIds;
    }

    if (bookingGroupIds !== null) {
        data.bookingGroupIds = bookingGroupIds;
    }

    const response = await fetch(`${API_URL}/api/products`, {
        method  : 'POST',
        body    : JSON.stringify(data),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to create product');
}

export async function updateEventGroup(eventGroupId, locationId = null, name = null, seats = null, color = null, description = null, cancellationCreditRefundPeriod = null, image = null) {
    if (!eventGroupId) {
        throw new Error("The eventGroupId is required.");
    }

    const token = localStorage.getItem('ACCESS_TOKEN');

    // Create FormData for file upload
    const formData = new FormData();
    if (locationId) {
        formData.append('locationId', locationId);
    }
    if (name) {
        formData.append('name', name);
    }
    if (seats) {
        formData.append('seats', seats);
    }
    if (color) {
        formData.append('color', color);
    }
    if (description) {
        formData.append('description', description);
    }
    if (cancellationCreditRefundPeriod) {
        formData.append('cancellationCreditRefundPeriod', cancellationCreditRefundPeriod);
    }
    if (image) {
        formData.append('image', image);
    } // Append the file if provided

    const response = await fetch(`${API_URL}/api/event-groups/${eventGroupId}`, {
        method  : 'POST',
        body    : formData,
        headers : {
            'Accept'        : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to update event group');
}

export async function updateBookingGroup(bookingGroupId, name, eventGroupIds) {
    if (!bookingGroupId || !name || !eventGroupIds) {
        throw new Error("All fields are required.");
    }

    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/booking-groups/${bookingGroupId}`, {
        method  : 'PATCH',
        body    : JSON.stringify({
            name          : name,
            eventGroupIds : eventGroupIds
        }),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to update booking group');
}

export async function updateProduct(
    productId,
    name = null,
    description = null,
    bruttoPrice = null,
    isRestrictedOnePerUser = null,
    isPublic = null,
    order = null,
    isShopVisibleAfterStart = null,
    credit = null,
    validity = null,
    activationType = null,
    validityUnit = null,
    eventGroupId = null,
    eventIds = null,
    bookingGroupIds = null
) {
    if (!productId) {
        throw new Error("The productId is required.");
    }

    const token = localStorage.getItem('ACCESS_TOKEN');

    const data = {};

    if (name !== null) {
        data.name = name;
    }

    if (description !== null) {
        data.description = description;
    }

    if (bruttoPrice !== null) {
        data.bruttoPrice = bruttoPrice;
    }

    if (isRestrictedOnePerUser !== null) {
        data.isRestrictedOnePerUser = isRestrictedOnePerUser;
    }

    if (isPublic !== null) {
        data.isPublic = isPublic;
    }

    if (order !== null) {
        data.order = order;
    }

    if (isShopVisibleAfterStart !== null) {
        data.isShopVisibleAfterStart = isShopVisibleAfterStart;
    }

    if (credit !== null) {
        data.credit = credit;
    }

    if (validity !== null) {
        data.validity = validity;
    }

    if (activationType !== null) {
        data.activationType = activationType;
    }

    if (validityUnit !== null) {
        data.validityUnit = validityUnit;
    }

    if (eventGroupId !== null) {
        data.eventGroupId = eventGroupId;
    }

    if (eventIds !== null) {
        data.eventIds = eventIds;
    }

    if (bookingGroupIds !== null) {
        data.bookingGroupIds = bookingGroupIds;
    }

    if (Object.keys(data).length === 0) {
        throw new Error("At least one field is required.");
    }

    const response = await fetch(`${API_URL}/api/products/${productId}`, {
        method  : 'PATCH',
        body    : JSON.stringify(data),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to update product');
}

export async function deleteProduct(productId) {
    if (!productId) {
        throw new Error("The productId is required.");
    }

    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/products/${productId}`, {
        method  : 'DELETE',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });
}

export async function findEventTypes(signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/event-types`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch event types');

    return response.json();
}

export async function findEventGroupEvents(eventGroupId, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/event-groups/${eventGroupId}/events`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch events');

    return response.json();
}

export async function findProductTypes(signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/product-types`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch product types');

    return response.json();
}

export async function findProductActivationTypes(signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/product-activation-types`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch product activation types');

    return response.json();
}

export async function findPaymentMethods(onlyManual, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    if (onlyManual) {
        params.append('onlyManual', 1);
    }

    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/order-payment-methods?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch order payment methods');

    return response.json();
}

export async function findProductValidityUnits(signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/product-validity-units`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch product validity units');

    return response.json();
}

export async function findEventGroups(eventType, signal = null) {
    if (eventType === null) {
        throw new Error("The eventType is required.");
    }
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('eventType', eventType);

    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/event-groups-all?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch event groups all');

    return response.json();
}

export async function findLocationsWithPagination(page, limit, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('limit', limit);

    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/locations?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch locations');

    return response.json();
}

export async function findEventSeriesWithPagination(
    eventGroupId,
    page,
    limit,
    filterStatus,
    signal = null
) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('limit', limit);

    if (filterStatus) {
        params.append('status', filterStatus);
    }

    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/event-groups/${eventGroupId}/event-series?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch event series');

    return response.json();
}

export async function findBookingsWithPagination(eventId, status, page, limit, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('limit', limit);
    params.append('eventId', eventId);

    if (status) {
        params.append(status, 1);
    }

    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/bookings?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch bookings');

    return response.json();
}

export async function createEventSeries(
    eventGroupId,
    startDate,
    startTime,
    endTime,
    trainers,
    endDate = null,
    monday = null,
    tuesday = null,
    wednesday = null,
    thursday = null,
    friday = null,
    saturday = null,
    sunday = null
) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/event-series`, {
        method  : 'POST',
        body    : JSON.stringify({
            eventGroupId : eventGroupId,
            startDate    : startDate,
            startTime    : startTime,
            endTime      : endTime,
            endDate      : endDate,
            monday       : monday,
            tuesday      : tuesday,
            wednesday    : wednesday,
            thursday     : thursday,
            friday       : friday,
            saturday     : saturday,
            sunday       : sunday,
            trainers     : trainers
        }),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to create event series');
}

export async function createBooking(eventId, customerId, withProduct) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/bookings`, {
        method  : 'POST',
        body    : JSON.stringify({
            eventId     : eventId,
            customerId  : customerId,
            withProduct : withProduct
        }),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to create booking');
}

export async function updateEventSeries(
    eventSeriesId,
    endDate = null,
    trainers = null
) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/event-series/${eventSeriesId}`, {
        method  : 'PATCH',
        body    : JSON.stringify({
            endDate  : endDate,
            trainers : trainers
        }),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to update event series');
}

export async function getEventSeries(eventSeriesId) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/event-series/${eventSeriesId}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to fetch event series');

    return await response.json();
}

export async function cancelEventSeries(eventSeriesId) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/event-series/${eventSeriesId}/cancel`, {
        method  : 'PATCH',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to cancel event series');
}

export async function cancelBooking(bookingId, withCreditRefund) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/bookings/${bookingId}/cancel`, {
        method  : 'POST',
        body    : JSON.stringify({withCreditRefund : withCreditRefund}),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to cancel booking');
}

export async function findEventListItemsWithPagination(page, limit, filterFromDate, filterStatus = null, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('limit', limit);

    if (filterFromDate) {
        params.append('fromDate', filterFromDate);
    }

    if (filterStatus) {
        params.append('status', filterStatus);
    }

    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/event-list-items?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch events');

    return response.json();
}

export async function sendInvoice(invoiceId) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/invoices/${invoiceId}/send`, {
        method  : 'POST',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to send invoice');
}

export async function cancelEvent(eventId) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/events/${eventId}/cancel`, {
        method  : 'POST',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to cancel event');
}

export async function uncancelEvent(eventId) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/events/${eventId}/uncancel`, {
        method  : 'POST',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to uncancel event');
}

export async function getMonthlyInvoicesZip(yearMonth) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/invoices/zip?yearMonth=${yearMonth}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/zip',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to fetch monthly invoices zip');

    return await response.blob();
}

export async function getOrdersReport(fromDate, toDate) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/orders/report?fromDate=${fromDate}&toDate=${toDate}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'text/csv',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to fetch orders report');

    return await response.blob();
}

export async function getPaymentsReport(fromDate, toDate) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/payments/report?fromDate=${fromDate}&toDate=${toDate}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'text/csv',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to fetch payments report');

    return await response.blob();
}

export async function getTrainer(trainerId) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/trainers/${trainerId}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to fetch trainer');

    return await response.json();
}

export async function createTrainer(firstName, lastName, displayName) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/trainers`, {
        method  : 'POST',
        body    : JSON.stringify({
            firstName   : firstName,
            lastName    : lastName,
            displayName : displayName
        }),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to create trainer');
}

export async function updateTrainer(trainerId, firstName, lastName, displayName) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/trainers/${trainerId}`, {
        method  : 'PATCH',
        body    : JSON.stringify({
            firstName   : firstName,
            lastName    : lastName,
            displayName : displayName
        }),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to update trainer');
}


export async function findTrainersWithPagination(page, limit, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('limit', limit);

    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/trainers?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch trainers');

    return response.json();
}