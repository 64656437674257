import {Fragment, useRef, useState} from 'react';
import Button from "../UI/Button";
import React from "react";
import Modal from "../UI/Modal";
import ListEdit from "../UI/ListEdit";
import ModalForm from "../UI/ModalForm";
import ModalFormInputRow from "../UI/ModalFormInputRow";
import Box from "../UI/Box";
import {toast} from "react-toastify";
import {useTrainers} from "../../hooks/useTrainers";
import {createTrainer, getTrainer, updateTrainer} from "../../api/eventGroupApiClient";

const TrainerList = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [trainer, setTrainer] = useState(null);
    const [submitCount, setSubmitCount] = useState(0);
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const displayNameRef = useRef(null);
    const {trainers, pagination} = useTrainers(page, pageSize, submitCount);

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const handlePageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        setPage(1);
    };

    const handleEditIconClick = (trainerId) => {
        setIsUpdate(true);
        const trainer = getTrainer(trainerId);

        trainer
            .then(
                (data) => {
                    setTrainer(data)
                    setShowModal(true)
                }
            )
            .catch(
                (error) => console.log(error)
            );
    }

    const handleCreateButtonClick = () => {
        setIsUpdate(false);
        setShowModal(true)
    }

    const handleCreateSubmit = (event) => {
        event.preventDefault();
        const firstName = firstNameRef.current.value;
        const lastName = lastNameRef.current.value;
        const displayName = displayNameRef.current.value;

        createTrainer(firstName, lastName, displayName)
            .then(() => {
                setShowModal(false)
                setSubmitCount(prevCount => prevCount + 1);
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    const handleEditSubmit = (event) => {
        event.preventDefault();
        const firstName = firstNameRef.current.value;
        const lastName = lastNameRef.current.value;
        const displayName = displayNameRef.current.value;

        updateTrainer(trainer.trainerId, firstName, lastName, displayName)
            .then(() => {
                setShowModal(false)
                setSubmitCount(prevCount => prevCount + 1);
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    const listHeaders = [
        'Id',
        'First name',
        'Last name',
        'Display name',
        'Actions'
    ];

    const columns = {
        functionParams : ['trainerId'],
        dataKeys       : [
            'trainerId',
            'firstName',
            'lastName',
            'displayName'
        ],
    }

    return <Fragment>
        <Box>
            <Button title='Create trainer' handleClick={handleCreateButtonClick}/>
            <ListEdit
                headers={listHeaders}
                items={trainers}
                columns={columns}
                pagination={pagination}
                handleEditIconClick={handleEditIconClick}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
            />
        </Box>
        {showModal && (
            <Modal>
                <form onSubmit={isUpdate ? handleEditSubmit : handleCreateSubmit}>
                    <ModalForm
                        title={isUpdate ? 'Update trainer' : 'Create trainer'}
                        saveButtonTitle={isUpdate ? 'Update' : 'Create'}
                        handleCancelButtonClick={() => setShowModal(false)}
                    >
                        <ModalFormInputRow title='First name'>
                            <input
                                ref={firstNameRef}
                                type="text"
                                id="firstName"
                                name="firstName"
                                defaultValue={isUpdate ? trainer.firstName : null}
                                placeholder="Enter first name"
                                className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                            />
                        </ModalFormInputRow>
                        <ModalFormInputRow title='Last name'>
                            <input
                                ref={lastNameRef}
                                type="text"
                                id="lastName"
                                name="lastName"
                                defaultValue={isUpdate ? trainer.lastName : null}
                                placeholder="Enter last name"
                                className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                            />
                        </ModalFormInputRow>
                        <ModalFormInputRow title='Display name'>
                            <input
                                ref={displayNameRef}
                                type="text"
                                id="displayName"
                                name="displayName"
                                defaultValue={isUpdate ? trainer.displayName : null}
                                placeholder="Enter display name"
                                className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                            />
                        </ModalFormInputRow>
                    </ModalForm>
                </form>
            </Modal>
        )}
    </Fragment>;
}

export default TrainerList;