import {Fragment, useState} from 'react';
import React from "react";
import {getInvoice, getInvoicePdf, getMonthlyInvoicesZip, sendInvoice} from "../../api/eventGroupApiClient";
import ListEdit from "../UI/ListEdit";
import Box from "../UI/Box";
import {convertToDisplayDate} from "../../utility/utility";
import {useCustomers} from "../../hooks/useCustomers";
import {useInvoices} from "../../hooks/useInvoices";
import {toast} from "react-toastify";
import Button from "../UI/Button";
import Modal from "../UI/Modal";
import ModalForm from "../UI/ModalForm";
import ModalFormInputRow from "../UI/ModalFormInputRow";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const InvoiceList = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [filterCustomerId, setFilterCustomerId] = useState(null);
    const {invoices, pagination} = useInvoices(filterCustomerId, page, pageSize, 1);
    const {customers} = useCustomers(1, 200, null, null, 'active', null, 0);
    const [selectedMonth, setSelectedMonth] = useState(new Date()); // Alapértelmezett mai dátum

    const invoiceList = invoices.map((item) => {
        return {
            invoiceId     : item.invoiceId,
            number        : item.number,
            paymentStatus : item.paymentStatus,
            customer      : item.customerName,
            createdAt     : convertToDisplayDate(item.createdAt),
            expiredAt     : convertToDisplayDate(item.expiredAt),
            tax           : item.taxPercentage + '%',
            bruttoPrice   : item.bruttoPrice + ' ' + item.currency,
        };
    });

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const handlePageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        setPage(1);
    };

    const handleDownloadIconClick = async (invoiceId) => {
        try {
            // Get the invoice details to retrieve the serial_number
            const invoice = await getInvoice(invoiceId);
            const serialNumber = invoice.number;

            // Fetch the PDF as a blob
            const blob = await getInvoicePdf(invoiceId);

            // Create a URL for the blob
            const url = window.URL.createObjectURL(blob);

            // Create a temporary <a> element to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${serialNumber}.pdf`); // Use the serial_number as filename
            document.body.appendChild(link);
            link.click();

            // Cleanup
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }
        catch (error) {
            console.error('Failed to download the invoice:', error.message);
            alert('Failed to download the invoice. Please try again.');
        }
    };

    const handleEnvelopeIconClick = (invoiceId) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Are you sure?')) {
            sendInvoice(invoiceId)
                .then(() => {
                    toast.success('Success!');
                })
                .catch(
                    (error) => {
                        toast.error(error.message);
                    }
                );
        }
    }

    const handleDownloadAllInvoicesButtonClick = () => {
        setShowModal(true);
    };

    const handleDownloadAllInvoices = async (event) => {
        event.preventDefault();
        const formattedDate = selectedMonth.toISOString().slice(0, 7); // "YYYY-MM" formátum

        try {
            const blob = await getMonthlyInvoicesZip(formattedDate);

            // Create a URL for the blob
            const url = window.URL.createObjectURL(blob);

            // Create a temporary <a> element to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `invoices_${formattedDate}.zip`);
            document.body.appendChild(link);
            link.click();

            // Cleanup
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }
        catch (error) {
            console.error('Failed to download the invoices:', error.message);
            alert('Failed to download the invoices. Please try again.');
        }
        setShowModal(false);
    };

    const listHeaders = [
        'Id',
        'Number',
        'Payment status',
        'Customer',
        'Invoice date',
        'Due date',
        'VAT',
        'Brutto price',
        'Actions'
    ];

    const columns = {
        functionParams : [
            'invoiceId'
        ],
        dataKeys       : [
            'invoiceId',
            'number',
            'paymentStatus',
            'customer',
            'createdAt',
            'expiredAt',
            'tax',
            'bruttoPrice'
        ],
    }

    return <Fragment>
        <Box>
            {/* Filters & Download Button in a single row */}
            <div className="flex justify-between items-center my-4">
                {/* Filters - Aligned Left */}
                <div className="flex space-x-4">
                    <select
                        id="customerId"
                        name="customerId"
                        onChange={(event) => {
                            const value = event.target.value === "" ? null : event.target.value;
                            setFilterCustomerId(value);
                        }}
                        className="border p-2 rounded-md"
                    >
                        <option value="">Filter by customers</option>
                        {customers && customers.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option.firstName} {option.lastName} {option.email ? `(${option.email})` : ''}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Download Invoices Button - Aligned Right */}
                <Button title='Download Invoices' handleClick={handleDownloadAllInvoicesButtonClick}/>
            </div>
            <ListEdit
                headers={listHeaders}
                items={invoiceList}
                columns={columns}
                pagination={pagination}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
                handleDownloadIconClick={handleDownloadIconClick}
                handleEnvelopeIconClick={handleEnvelopeIconClick}
            />
        </Box>
        {showModal && (<Modal>
            <form onSubmit={handleDownloadAllInvoices}>
                <ModalForm
                    title={'Download invoices'}
                    saveButtonTitle={'Export'}
                    handleCancelButtonClick={() => setShowModal(false)}
                >
                    <ModalFormInputRow title='Download the invoices of:'>
                        <DatePicker
                            selected={selectedMonth}
                            onChange={(date) => setSelectedMonth(date)}
                            dateFormat="yyyy-MM"
                            showMonthYearPicker
                            className="border rounded-lg py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline"
                        />
                    </ModalFormInputRow>
                </ModalForm>
            </form>
        </Modal>)}
    </Fragment>;
}

export default InvoiceList;