import React from 'react';
import {NavLink} from "react-router-dom";

const Sidebar = () => {
    return (
        <nav className="bg-gray-800 h-screen w-64 px-4 py-8">
            <div className="flex items-center mb-6">
                <img
                    className="h-8 w-8 mr-2"
                    src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
                    alt="Workflow"
                />
                <NavLink to='/'>
                    <span className="text-white text-xl font-bold">PegaSoft</span>
                </NavLink>
            </div>
            <div>
                <h3 className="text-white font-medium text-sm uppercase mb-4">Master data</h3>
                <ul>
                    <li className="mb-2">
                        <NavLink to='/users' className="text-gray-300 hover:text-white">Users</NavLink>
                    </li>
                    <li className="mb-2">
                        <NavLink to='/grips' className="text-gray-300 hover:text-white">Grips</NavLink>
                    </li>
                    <li className="mb-2">
                        <NavLink to='/figure-categories' className="text-gray-300 hover:text-white">Figure categories</NavLink>
                    </li>
                    <li className="mb-2">
                        <NavLink to='/figures' className="text-gray-300 hover:text-white">Figures</NavLink>
                    </li>
                    <li className="mb-2">
                        <NavLink to='/thematics' className="text-gray-300 hover:text-white">Thematics</NavLink>
                    </li>
                    <li className="mb-2">
                        <NavLink to='/levels' className="text-gray-300 hover:text-white">Levels</NavLink>
                    </li>
                </ul>
            </div>
            <div className="mt-6">
                <h3 className="text-white font-medium text-sm uppercase mb-4">Live data</h3>
                <ul>
                    <li className="mb-2">
                        <NavLink to='/customer-thematics' className="text-gray-300 hover:text-white">Figure progress tracker</NavLink>
                    </li>
                    <li className="mb-2">
                        <NavLink to='/customers' className="text-gray-300 hover:text-white">Customers</NavLink>
                    </li>
                    <li className="mb-2">
                        <NavLink to='/customer-level-requirement-summaries' className="text-gray-300 hover:text-white">Customer level checker</NavLink>
                    </li>
                    <li className="mb-2">
                        <NavLink to='/all-customer-comments' className="text-gray-300 hover:text-white">Customer comments</NavLink>
                    </li>
                </ul>
            </div>
            <div className="mt-6">
                <h3 className="text-white font-medium text-sm uppercase mb-4">Booking system</h3>
                <ul>
                    <li className="mb-2">
                        <NavLink to='/trainers' className="text-gray-300 hover:text-white">Trainers</NavLink>
                    </li>
                    <li className="mb-2">
                        <NavLink to='/event-groups' className="text-gray-300 hover:text-white">Events</NavLink>
                    </li>
                    <li className="mb-2">
                        <NavLink to='/booking-groups' className="text-gray-300 hover:text-white">Booking groups</NavLink>
                    </li>
                    <li className="mb-2">
                        <NavLink to='/products' className="text-gray-300 hover:text-white">Products</NavLink>
                    </li>
                    <li className="mb-2">
                        <NavLink to='/events' className="text-gray-300 hover:text-white">Event List</NavLink>
                    </li>
                    <li className="mb-2">
                        <NavLink to='/customer-products' className="text-gray-300 hover:text-white">Customer Products</NavLink>
                    </li>
                    <li className="mb-2">
                        <NavLink to='/orders' className="text-gray-300 hover:text-white">Sales</NavLink>
                    </li>
                    <li className="mb-2">
                        <NavLink to='/payments' className="text-gray-300 hover:text-white">Payments</NavLink>
                    </li>
                    <li className="mb-2">
                        <NavLink to='/invoices' className="text-gray-300 hover:text-white">Invoices</NavLink>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Sidebar;