import {Fragment, useState} from 'react';
import React from "react";
import {cancelOrder, createInvoice, getMonthlyInvoicesZip, getOrdersReport} from "../../api/eventGroupApiClient";
import ListEdit from "../UI/ListEdit";
import Box from "../UI/Box";
import {toast} from "react-toastify";
import {convertToDisplayDate} from "../../utility/utility";
import {useCustomers} from "../../hooks/useCustomers";
import {useOrders} from "../../hooks/useOrders";
import {useOrdersTotal} from "../../hooks/useOrdersTotal";
import Modal from "../UI/Modal";
import ModalForm from "../UI/ModalForm";
import Button from "../UI/Button";

const OrderList = (props) => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [submitCount, setSubmitCount] = useState(0);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [cancelOrderId, setCancelOrderId] = useState(null);
    const [filterCustomerId, setFilterCustomerId] = useState(null);
    const [filterFromDate, setFilterFromDate] = useState(null);
    const [filterToDate, setFilterToDate] = useState(null);
    const {orders, pagination} = useOrders(filterCustomerId, null, filterFromDate, filterToDate, page, pageSize, submitCount);
    const {total} = useOrdersTotal(filterFromDate, filterToDate);
    const {customers} = useCustomers(1, 200, null, null, 'active', null, 0);

    const orderList = orders.map((item) => {
        return {
            orderId       : item.orderId,
            orderNumber   : item.number,
            paymentStatus : item.paymentStatus,
            customer      : item.customerName,
            orderDate     : convertToDisplayDate(item.createdAt),
            paymentMethod : item.paymentMethod,
            invoiceNumber : item.invoiceSerialNumber,
            productName   : item.productName,
            origin        : item.origin,
            bruttoPrice   : item.bruttoPrice + ' ' + item.currency
        };
    });

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const handlePageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        setPage(1);

    };

    const handleInvoiceIconClick = (orderId) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Are you sure?')) {
            createInvoice(orderId)
                .then(() => {
                    setSubmitCount(prevCount => prevCount + 1);
                    toast.success('Success!');
                })
                .catch(
                    (error) => {
                        toast.error(error.message);
                    }
                );
        }
    }

    const handleCancelIconClick = (orderId) => {
        // eslint-disable-next-line no-restricted-globals
        setShowCancelModal(true);
        setCancelOrderId(orderId);
    }

    const handleCancelSubmit = (event) => {
        event.preventDefault();

        const orderId = cancelOrderId;

        cancelOrder(orderId)
            .then(() => {
                setShowCancelModal(false);
                setSubmitCount(prevCount => prevCount + 1);
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    const handleDownloadReportButtonClick = async () => {
        if (!filterFromDate || !filterToDate) {
            alert('Please select the date range.');
            return;
        }

        // DD-MM-YYYY formátum
        const from = filterFromDate.split('-').reverse().join('-');
        const to = filterToDate.split('-').reverse().join('-');

        try {
            const blob = await getOrdersReport(filterFromDate, filterToDate);

            // Create a URL for the blob
            const url = window.URL.createObjectURL(blob);

            // Create a temporary <a> element to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `sales_${from}_${to}.csv`);
            document.body.appendChild(link);
            link.click();

            // Cleanup
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }
        catch (error) {
            console.error('Failed to download the report:', error.message);
            alert('Failed to download the report. Please try again.');
        }
    };

    const listHeaders = [
        'Id',
        'Order number',
        'Payment status',
        'Customer name',
        'Order date',
        'Payment method',
        'Invoice',
        'Product',
        'Origin',
        'Brutto price',
        'Actions'
    ];

    const columns = {
        functionParams : [
            'orderId'
        ],
        dataKeys       : [
            'orderId',
            'orderNumber',
            'paymentStatus',
            'customer',
            'orderDate',
            'paymentMethod',
            'invoiceNumber',
            'productName',
            'origin',
            'bruttoPrice'
        ],
    }

    return <Fragment>
        <Box>
            <div className="flex justify-between items-center my-4">
                <div className="flex space-x-4">
                    <select
                        id="customerId"
                        name="customerId"
                        onChange={(event) => {
                            const value = event.target.value === "" ? null : event.target.value;
                            setFilterCustomerId(value);
                        }}
                        className="border p-2 mr-2"
                    >
                        <option value="">Filter by customers</option>
                        {customers && customers.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option.firstName} {option.lastName} {option.email ? `(${option.email})` : ''}
                            </option>
                        ))}
                    </select>
                    <input
                        type="date"
                        className="border p-2 mr-2"
                        onChange={(event) => setFilterFromDate(event.target.value)}
                    />
                    <input
                        type="date"
                        className="border p-2 mr-2"
                        onChange={(event) => setFilterToDate(event.target.value)}
                    />
                </div>
                <Button title='Download report' handleClick={handleDownloadReportButtonClick}/>
            </div>
            <h2 className="text-md font-bold text-gray-800 mb-4 text-center">
                Total incl. VAT: €{total}
            </h2>
            <ListEdit
                headers={listHeaders}
                items={orderList}
                columns={columns}
                pagination={pagination}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
                handleInvoiceIconClick={handleInvoiceIconClick}
                handleCancelIconClick={handleCancelIconClick}
            />
        </Box>
        {showCancelModal && (
            <Modal>
                <form onSubmit={handleCancelSubmit}>
                    <ModalForm
                        title={'Are you sure you want to cancel this order?'}
                        saveButtonTitle={'OK'}
                        handleCancelButtonClick={() => setShowCancelModal(false)}
                    >
                        <div className="flex flex-col">
                            <p className="text-sm text-gray-600">
                                This action will:
                            </p>
                            <ul className="list-disc list-inside text-sm text-gray-600">
                                <li>A negative payment will be created if a payment is linked to this order.</li>
                                <li>A cancellation invoice will be generated if an invoice is linked to this order.</li>
                                <li>The order status and payment status will be updated to "Cancelled."</li>
                                <li><b>Important:</b> You must manually refund the payment through the PayPal or Stripe dashboard!</li>
                            </ul>
                        </div>
                    </ModalForm>
                </form>
            </Modal>
        )}
    </Fragment>;
}

export default OrderList;